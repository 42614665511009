// @flow
import React from 'react';
import NoPayment12Pay from 'src/components/Landing/NoPayment12Pay';
import { Layout } from 'src/components';
import { SITE_METADATA } from 'src/constants';
import UseInstallments12Pay from 'src/components/Landing/UseInstallments12Pay';
import VerifyIdentity from 'src/components/Landing/VerifyIdentity';
import styles from '../styles/pages/landing.module.scss';
import ConversionTop6Pay from '../components/Landing/ConversionTop6Pay';
import Point from '../components/Landing/Point';
import Step from '../components/Landing/Step';
import Can from '../components/Landing/Can';
import ConversionBottom12Pay from '../components/Landing/ConversionBottom12Pay';
import Plus12Pay from '../components/Landing/Plus12Pay';
import Footer6Pay from '../components/Landing/Footer6Pay';
import TopBanner from '../components/Landing/TopBanner';

const SEOProps = {
  title: SITE_METADATA.landing_12pay.title,
  description: SITE_METADATA.landing_12pay.description,
};

export default function Landing12Pay() {
  return (
    <Layout SEOProps={SEOProps} hasNoFooter hasNoHeader>
      <div className={styles.landing}>
        <TopBanner />
        <ConversionTop6Pay />
        <Point />
        <Step />
        <NoPayment12Pay />
        <UseInstallments12Pay />
        <VerifyIdentity />
        <Can />
        <Plus12Pay />
        <ConversionBottom12Pay />
        <Footer6Pay />
      </div>
    </Layout>
  );
}
